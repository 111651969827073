<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Filtros" icon="pi pi-plus" class="p-button-success mr-2"
                                @click="abrirFiltros" />

                        </div>
                    </template>

                    <template v-slot:end>
                        <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>
                </Toolbar>

                <!-- Tabela de modos de falha -->
                <DataTable ref="dt" :value="errosCockpit" sortField="data_ajuste" :sortOrder="1" v-model:expandedRows="selectImplantacoes" dataKey="id"
                    :paginator="true" :rows="20" :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Cockpit - Auditadas</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <!-- <i class="pi pi-search" /> -->
                                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <!-- 
                    <Column field="id" header="ID" :sortable="true" headerStyle="width:3%; min-width:3rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">ID</span>
                            <span>{{ slotProps.data.id_fila }}</span>
                        </template>
                    </Column> -->

                    <Column field="extracao" header="Nº Extração" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Extração</span>
                            <span>{{ slotProps.data.fatura?.id_extracao ?? '' }}</span>
                        </template>
                    </Column>

                    <Column field="fatura" header="Id Fatura" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Fatura</span>
                            <span>{{ slotProps.data.fatura?.id ?? '' }}</span>
                        </template>
                    </Column>

                    <Column field="razao_social" header="Empresa" :sortable="true"
                        headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Empresa</span>
                            <span>{{ slotProps.data.cliente.razao_social }}</span>
                        </template>
                    </Column>

                    <Column field="quantidade_arquivos" header="Modo de Falha" :sortable="true"
                        style="text-align: center;" headerStyle="width:15%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Modo de Falha</span>
                            <Tag severity="primary" 
                                    :style="{ backgroundColor: '#ff4d4f',
                                    width: '100%',
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                    }"
                                v-if="slotProps.data.tipo == 0" :value="slotProps.data.status_erro.descricao">
                            </Tag>

                            <Tag severity="primary"
                            :style="{ backgroundColor: 'orange',
                                    width: '100%',
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                    }"
                                v-if="slotProps.data.tipo == 1" value="Erro de implantação">
                            </Tag>
                        </template>
                    </Column>

                    <Column field="status" header="Status" :sortable="true" headerStyle="width:15%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Status</span>

                            <Tag severity="primary" 
                                :style="{ backgroundColor: '#4caf50',
                                    width: '100%',
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                    }"
                                :value="slotProps.data.status === 0 ? 'Aguardando correção' : 'Corrigido'"
                            >
                            </Tag>

                        </template>
                    </Column>

                    <Column field="data_fila" header="Data Envio" :sortable="true"
                        headerStyle="width:15%; min-width:2rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Data</span>
                            {{ slotProps.data.fila.data_criacao }}
                        </template>
                    </Column>

                    <Column field="data_ajuste" header="Data de Correção" :sortable="true"
                        headerStyle="width:15%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Data</span>
                            {{ slotProps.data.data_ajuste }}
                        </template>
                    </Column>

                    <Column field="link_fatura" header="Fatura" :sortable="true" headerStyle="width:18%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Link fatura</span>

                            <Button
                                label="Erros auditados"
                            class="p-button-sm p-button-grey"
                            @click="abrirModalErros(slotProps.data.fatura.id)"
                            />
                        </template>
                    </Column>

                </DataTable>

                <Dialog v-model:visible="modalErros" :style="{ width: '40%' }" header="Erros - Implantação" :modal="true"
                    class="p-fluid">
                    <div class="field">
                        <textarea :disabled="true" id="erros" v-model.trim="this.implantacao_erros" required="true" autofocus style="width: 100%; height: 200px;" />

                    </div>
                    <template #footer>
                        <Button label="Fechar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="this.modalErros = false" />
                        <!-- <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="Salvar()" /> -->
                    </template>
                    </Dialog>


                
            </div>
        </div>
    </div>

    <div v-if="isLoading" class="loading-bar-container">
      <div class="loading-bar"></div>
    </div>
</template>

<style>
.loading-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1); /* Fundo leve para destacar o progresso */
  z-index: 9999;
}

.loading-bar {
  height: 100%;
  background-color: #53C65F; /* Cor de sucesso */
  width: 0; /* Começa vazia */
  animation: loading-animation 2.5s linear forwards; /* Animação predefinida */
}

@keyframes loading-animation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>

<script>

import { FilterMatchMode } from "primevue/api";
import funcoes from '../../funcoes.js';

export default {
    mixins: [funcoes],
    data() {
        return {
            tipos_erros: [
                { label: "Layout não Encontrado", value: 1 },
                { label: "Campos não Mapeados", value: 2 },
                { label: "Campos não Extraidos ", value: 3 },
                { label: "Implantação Divergente", value: 4 },
            ],
            isLoading: false,
            loadingBarColor: '',
            loading: false,
            filtros: [],
            ModalFiltros: false,
            
            selectImplantacoes: null,
        
            errosCockpit: [],
            errosCockpitFatura: [],
            tiposItensNormalizados: [],
            tiposClassesUnidades: [],
            tiposSubClassesUnidades: [],
            tiposModalidadesUnidades: [],

            fatura: {},
            modalErros: false,

            itemFaturado: {},
            modalItemFaturado: false,

            medidor: {},
            modalEditMedidor: false,
            medidorDialog: false,

            dados_faturas: {},
            modalEditDados: false,

            dados_fiscais: {},
            modalEditDadosFiscais: false,

            dados_tributos: {},
            modalEditDadosTributos: false,

            deleteModalFatura: false,
            deleteMedidorDialog: false,
            deleteDadosDialog: false,
            deleteDadosFiscaisDialog: false,
            deleteDadosTributosDialog: false,
            deleteItensFaturadosDialog: false,
            deleteProductsDialog: false,

            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            submitted: false,

            tipos_status: [
                { label: "Processo Iniciado", value: 0 },
                { label: "Separação Iniciada", value: 1 },
                { label: "Separação Finalizada", value: 2 },
                { label: "Extração Iniciada", value: 3 },
                { label: "Extração Finalizada", value: 4 },
                { label: "Implantação Iniciada", value: 5 },
                { label: "Implantação Finalizada", value: 6 },
            ],

            implantacao_erros: "",

            tipos_empresas: [],
            desc_tipos_empresas: [],
            tipos_insumo: [],
            tipos_modalidades: [],
            tipos_concess: [],
            tipos_pagamentos: [],
            tipos_classe_tarifaria: [],
            tipos_subclasse: [],
            tipos_tensao: [],
            tipos_ligacao: [],
            status_array: [
                'Upload Iniciado',
                'Separação Iniciada ',
                'Separação Finalizada',
                'Extração Iniciada',
                'Extração Finalizada',
                'Implantação Iniciada',
                'Implantação Finalizada'
            ]
        };
    },

    created() {
        this.initFilters();
    },

    mounted() {
        this.listarAuditadasCockpit();
    },

    methods: {

        abrirModalErros(id_fatura) {
            this.axios
                .post(`/cockpit/listar-erros-auditadas/${id_fatura}`)
                .then((response) => {
                    console.log(response);
                    this.implantacao_erros = response.data.erros;
                    this.modalErros = true;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
                });
            },

        /** 
         * listarAuditadasCockpit - list all cockpit invoices fixed
         */
        listarAuditadasCockpit() {
            this.axios
                .get("/cockpit/listar-auditadas")
                .then((response) => {
                    this.errosCockpit = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
                });
        },



        hideDialog() {
            this.modalErros = false;
            this.submitted = false;
        },



        exportCSV() {
            this.$refs.dt.exportCSV();
        },


        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },

    },
};

</script>
